import React from 'react'

const Lightbox = (props) => {
  const imageStyle = {
    border: '1px solid #fff',
    borderRadius: '8px',
  };

  return (
    <div className='hide' id='lightbox' onClick={props.open}>
        <img id="lightboxImage" style={imageStyle} alt='Project'/>
    </div>
  )
} // Lightbox

export default Lightbox