import React from 'react';
import styled from 'styled-components/';
import SkillsBkg from '../images/skillsBkg.jpg';
import SkillsInfo from './SkillsInfo';

const SkillsWrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-image: url(${SkillsBkg});
  background-size: cover;
  background-position: center;
  color: #fff;
  font-weight: 700;
  justify-content: center;
  margin-top: 0px;
  object-fit: fit;
`; // SkillsWrapper

const SkillsH1 = styled.h1`
  -webkit-text-fill-color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  color: #fff;
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1px;
  margin-left: 55px;
  margin-top: -675px;
`; // SkillsH1

const Skills = () => {
  return (  
    <>
      <SkillsWrapper id='Skills' >     
        <SkillsH1>Skills</SkillsH1>
      </SkillsWrapper>
      <SkillsInfo />
    </>
  );
} // Skills

export default Skills;

