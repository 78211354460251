import React from 'react';
import styled from 'styled-components/';
import ps1 from '../images/portfolio_images/ps1.jpg'
import os1 from '../images/portfolio_images/os1.png'
import ps2 from '../images/portfolio_images/ps2.jpg'
import os2 from '../images/portfolio_images/os2.png'
import ps3 from '../images/portfolio_images/ps3.jpg'
import os3 from '../images/portfolio_images/os3.png'
import ps4 from '../images/portfolio_images/ps4.jpg'
import os4 from '../images/portfolio_images/os4.png'
import ps5 from '../images/portfolio_images/ps5.jpg'
import os5 from '../images/portfolio_images/os5.png'
import ps6 from '../images/portfolio_images/ps6.jpg'
import os6 from '../images/portfolio_images/os6.png'

const ProjectsStyle = styled.div`
height: 100vh;
width: 100%;
display: flex;
flex-direction: column;
background: transparent;
color: #fff;
justify-content: center;    
margin-top: 0px;

@media screen and (max-width: 990px) {
  margin-bottom: 200px ;
}
@media screen and (max-width: 665px) {
  margin-bottom: 400px ;     
}
@media screen and (max-width: 590px) {
  margin-bottom: 700px ;     
}
@media screen and (max-width: 515px) {
  margin-bottom: 1000px ;     
}
`;

const title = {
  WebkitTextFillColor: '#fff',
  WebkitTextStrokeWidth: '1px',
  WebkitTextStrokeColor: '#000',
  fontSize: '3rem',
  fontWeight: '900',
  marginTop: '-65px',
  marginLeft: '55px'
}

const imgsection = {
  height: '65vh',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  flexFlow: 'row wrap',
  flexBoxShrink: '1',
  alignItems: 'center',
  justifyContent: 'center',
}

const thumbStyleTall = {
  height: '300px',
  width: '225px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  border: '1px solid #eee',
  borderRadius: '8px',
  boxShadow: '2px 2px 5px #ccc',
  cursor: 'pointer',
  justifyContent: 'center', 
  margin: '12px',
}

const thumbStyleWide = {
  height: '225px',
  width: '300px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  border: '1px solid #eee',
  borderRadius: '8px',
  boxShadow: '2px 2px 5px #ccc',
  cursor: 'pointer',
  justifyContent: 'center', 
  margin: '12px',
  objectFit: 'fit',
}

const Projects = (props) => {
  return (
    <>
      <ProjectsStyle id='Projects'>
        <h1 style={title}>Projects</h1>
        <div style={imgsection}>
          <img style={thumbStyleTall} onClick={() => props.open(os1)} src={ps1} alt='Project 1'/>
          <img style={thumbStyleTall} onClick={() => props.open(os2)} src={ps2} alt='Project 2'/>
          <img style={thumbStyleTall} onClick={() => props.open(os3)} src={ps3} alt='Project 3'/>
          <img style={thumbStyleWide} onClick={() => props.open(os4)} src={ps4} alt='Project 4'/>
          <img style={thumbStyleWide} onClick={() => props.open(os5)} src={ps5} alt='Project 4'/>
          <img style={thumbStyleWide} onClick={() => props.open(os6)} src={ps6} alt='Project 4'/>
        </div>
      </ProjectsStyle> 
    </>
  )
} // Projects

export default Projects