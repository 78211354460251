import React from 'react'
import styled from 'styled-components'
import FlippingCard from './FlippingCard'

const InfoTextWrapper = styled.div`
  height: 30vh;
  width: 100%;
  display: flex;
  flex-flow: column;
  flex-shrink: 2;
  align-content: flex-end;
  justify-content: flex-start;
  margin-top: 100px;
  margin-left: 20px;
  overflow: hidden;

  @media screen and (max-width: 600px) {
    Margin-top: 100px;}
`; // InfoTextWrapper

const CardWrapper = styled.div`
  height: 70vh;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: left;
  margin-left: 75px;
  margin-top: -200px;
`; // CardWrapper

const Introduce = styled.h1`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-shrink: 2;
  color: #000;
  font-size: 3rem;
  margin-top: 0px;
  margin-left: 25px;
  overflow: hidden;

  @media screen and (max-width: 600px) {
    font-size: 28px;}
  @media screen and (max-width: 970px) {
    font-size: 35px;}
`; // Styled H1

const AboutP = styled.p`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-shrink: 3;
  color: #000;
  font-size: 1.6rem;
  font-weight: 900;
  margin-top: 0px;
  margin-left: -30px;
  padding: 4px 50px;

  @media screen and (max-width: 1040px) {
    font-size: 1rem;
    margin-top: 8px;
  }
`; // Styled P

function AboutInfo() {
  return (
    <>
    <InfoTextWrapper>
      <Introduce>Let Me Introduce Myself</Introduce>
      <AboutP> - Majored in System Analysis and Design</AboutP>
      <AboutP> - Doing my major in Enterprise Web and Mobile Developement with triOS College</AboutP>
      <AboutP> - I drink coffee, eat pizza, and write code, always improving on my gained skills</AboutP>
      <AboutP> - Let's work together on your next project, with the skills I have aquired to get you up and running in a timely manner, with a site to be amazed with</AboutP>
    </InfoTextWrapper>
    <CardWrapper>
      <FlippingCard />
    </CardWrapper>
    </>
  )
} // AboutInfo

export default AboutInfo;