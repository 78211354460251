
import React from 'react';
import styled from 'styled-components/';
import HeadBkg from '../images/headerBkg.jpg';

const HeaderDiv = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  background-image: url(${HeadBkg});
  background-position: center center;
  margin-top: 0px;
`;

const Header = () => {
  return (
    <HeaderDiv></HeaderDiv>
  )
} // Header

export default Header;

