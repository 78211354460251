
import React, { useState } from 'react';
import logo from '../images/logo.png';
import './Navbar.css';

const Navbar = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const MobileMenu = () => setClick(false);

  return (
    <>
      <nav className='navbar'>
        <div className='navContainer'>
          <a href='#Home'><img src={logo} className='navLogo' alt='logo'/></a>
          <div className='menuIcon' onClick={handleClick}>
              <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <div name='Home' id='navChoices' className={click ? 'navMenuActive' : 'navMenu'}> 
            <a href='#Home' name='Home' className='navLinks' onClick={MobileMenu}>Home</a>
            <a href='#Intro' name='Intro' className='navLinks' onClick={MobileMenu}>Intro</a>
            <a href='#About' name='About' className='navLinks' onClick={MobileMenu}>About</a>
            <a href='#Skills' name='Skills' className='navLinks' onClick={MobileMenu}>Skills</a>
            <a href='#Projects' name='Projects' className='navLinks' onClick={MobileMenu}>Projects</a>
            <a href='#Contact' name='Contact' className='navLinks' onClick={MobileMenu}>Contact</a> 
          </div>
        </div>
      </nav>
    </>
  );
} // Navbar

export default Navbar;
