import React from 'react'
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import Rotate from 'react-reveal/Rotate';
import Zoom from 'react-reveal/Zoom';
import styled, { keyframes } from 'styled-components';
import html from '../images/html.png';
import css from '../images/css.png';
import javaScript from '../images/javaScript.png';
import cSharp from '../images/cSharp.png';
import java from '../images/java.png';
import sql from '../images/sql.png';
import react from '../images/react.png';
import jQuery from '../images/jQuery.png';
import android from '../images/android.jpg';
import PsLogo from '../images/PsLogo.png';
import AiLogo from '../images/AiLogo.png';
import XdLogo from '../images/XdLogo.png';

const SkillsInfoWrapper = styled.div`
  width: 95vw;
  height: 65vh;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  align-content: space-around;
  align-items: center;
  background: transparent;
  color: #fff;
  justify-content: center;
  margin: -800px 0 200px 0;

  @media screen and (max-width: 700px) {
    margin-top: -750px;
  }
`;

const LanguageHead = styled.div`
  -webkit-text-fill-color: oldlace;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  width: 100%;
  display: flex;
  flex-shrink: 2;
  align-content: center;
  align-items: center;
  color: oldlace;
  font-size: 3rem;
  font-weight: 700;
  justify-content: center;
  padding: 20px 20px;
  overflow: hidden;

  @media screen and (max-width: 700px) {
    width: 100%;
    color: oldlace;
    font-size: 2.1rem;
    font-weight: 700;
    margin-top: 30px;
    padding: 20px 20px;
  }
`;

const Languages = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  flex-shrink: 1;
  align-items: center;
  gap: 40px;
  justify-content: center;
`;

const GraphicsHead = styled.div`
  -webkit-text-fill-color: oldlace;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  align-items: center;
  color: oldlace;
  font-size: 3rem;
  font-weight: 700;
  justify-content: center;
  padding: 20px 20px;

  @media screen and (max-width: 700px) {
    width: 100%;
    color: oldlace;
    font-size: 2.1rem;
    font-weight: 700;
    padding: 20px 20px;
  }
`;

const GraphicPackages = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  flex-shrink: 1;
  align-items: center;
  gap: 40px;
  justify-content: center;
  padding: 10px 20px;
`;

const RotateAnimation = keyframes`
  0% { transform: rotateX(0deg); }
  100% { transform: rotateX(360deg); }
`;

const Rotating1 = styled.img`
  height: 100px;
  width: 100px;
  animation: ${RotateAnimation} 4s linear infinite;
  transform: rotateX(0deg);
`;

const Rotating2 = styled.img`
  height: 100px;
  width: 100px;
  animation: ${RotateAnimation} 6s linear infinite;
  transform: rotateX(0deg);
`;

const Rotating3 = styled.img`
  height: 100px;
  width: 100px;
  animation: ${RotateAnimation} 8s linear infinite;
  transform: rotateX(0deg);
`;

function SkillsInfo() {
  return (
    <>
      <SkillsInfoWrapper>
        <Zoom left duration={'3000'}>
          <LanguageHead>Web Design Tools</LanguageHead>
        </Zoom>
        <Languages>
          <Fade bottom duration={'4000'} >
            <Rotating1 src={html} width='100px' height='100px' alt='html logo'/>
          </Fade>
          <Fade top duration={'3000'} >
            <Rotating2 src={css} width='100px' height='100px' alt='css logo'/>
          </Fade>
          <Fade bottom duration={'4000'}>
            <Rotating3 src={javaScript} width='100px' height='100px' alt='js logo'/>
          </Fade>
          <Zoom duration={'4000'}>
            <Rotating1 src={cSharp} width='100px' height='100px' alt='cSharp logo'/>
          </Zoom>            
          <Zoom duration={'2000'}>
            <Rotating2 src={java} width='100px' height='100px' alt='java logo'/>
          </Zoom>
          <Zoom duration={'4000'}>
            <Rotating3 src={sql} width='100px' height='100px' alt='sql logo'/>
          </Zoom>
          <Fade bottom duration={'4000'}>
            <Rotating1 src={react} width='100px' height='100px' alt='react logo'/>              
          </Fade>
          <Fade top duration={'3000'}>
            <Rotating2 src={jQuery} width='100px' height='100px' alt='jQuery logo'/>
          </Fade>
          <Fade bottom duration={'4000'}>
            <Rotating3 src={android} width='100px' height='100px' alt='android logo'/>
          </Fade>
        </Languages>
        <Zoom right duration={'3000'}>
          <GraphicsHead>Graphic UX/UI Design</GraphicsHead>
        </Zoom>
        <GraphicPackages>
          <Rotate top left duration={'3000'}>
            <Rotating3 src={PsLogo} width='100px' height='100px' alt='Ps logo'/>
          </Rotate>
          <Flip left duration={'5000'}>
            <Rotating2 src={AiLogo} width='100px' height='100px' alt='Ai logo'/>
          </Flip>
          <Rotate top right duration={'3000'}>
            <Rotating1 src={XdLogo} width='100px' height='100px' alt='Xd logo'/>
          </Rotate>
        </GraphicPackages>
      </SkillsInfoWrapper> 
    </>            
  )
} // SkillsInfo

export default SkillsInfo;