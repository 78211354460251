
import React from 'react';
import Header from './Header';
import IntroImg from '../images/introBkg.jpg';
import styled, { keyframes } from 'styled-components';
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import Rotate from 'react-reveal/Rotate';
import Zoom from 'react-reveal/Zoom';
import './Intro.css';

const IntroWrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(${IntroImg});
  background-size: cover;
  background-position: center center;
  justify-content: center;
  object-fit: fit;
  overflow: hidden;
`;

const RotateAnimation = keyframes`
  0% { transform: rotateY(0deg); }
  100% { transform: rotateY(360deg); }
`;

const Rotating = styled.h1`
  -webkit-text-fill-color: oldlace;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
  height: 20vh;
  display: flex;
  flex-direction: column;
  color: oldlace;
  font-family: 'Oleo Script', cursive;
  font-size: 5rem;
  margin: 25px 0px;
  overflow-x: hidden;
  transform: rotateY(0deg);
  animation: ${RotateAnimation} 10s linear infinite;

  @media screen and (max-width: 1200px) { font-size: 60px;}
  @media screen and (max-width: 960px) { font-size: 50px;}
  @media screen and (max-width: 630px) { font-size: 40px;}
`;

const Intro = () => {
  return (
    <IntroWrapper id='Intro'>
      <Header />
      <Flip duration={'1000'}>
        <Rotating>Charles David Gamble</Rotating>
      </Flip>
      <Fade right duration={'2000'}>
        <h2 id='dev'>Web and Mobile Developer</h2>
      </Fade>
      <Fade left duration={'2000'}>
        <h2 id='site'>Your Website Awaits</h2>
      </Fade>
      <Fade right duration={'2000'}>
        <div className='waiting'>
          <p>What are you waiting for?</p>
        </div>
      </Fade>
      <Fade left duration={'2000'}>
        <div className='building'>  
          <p>Lets start building</p>
        </div>
      </Fade>
      <Zoom left duration={'3000'}>
        <div className='dreamBox'>
          <p className='dream'>YOUR DREAM SITE</p>
        </div>
      </Zoom> 
      <Rotate bottom>
        <div className='btnGetStarted'>
          <a className='contactLink' href='#Contact'>Get Started</a>
        </div>
      </Rotate>
    </IntroWrapper>
  )
} // Intro

export default Intro
