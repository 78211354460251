import React from 'react';
import styled from 'styled-components/';
import contactBkg from '../images/contactBkg.jpg';
import ContactInfo from './ContactInfo';
import Footer from './Footer'

const ContactWrapper = styled.div`
height: 100vh;
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
background-image: url(${contactBkg});
background-size: cover;
background-position: center;
color: #fff;
object-fit: fit;

@media screen and (max-width: 750px) {
  height: 130vh;
}
`; // ContactWrapper

const ContactH1 = styled.h1`
  -webkit-fext-fill-color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  font-size: 3rem;
  font-weight: 700;
  margin-top: 105px;
  margin-left: 55px;
`; // ContactH1

function Contact() {
  return (
    <>  
      <ContactWrapper id='Contact'>     
        <ContactH1>Contact</ContactH1>
        <ContactInfo />
        <Footer />
      </ContactWrapper>
    </>
  );
} // Contact

export default Contact;
