import React from 'react'
import './ContactInfo.css'
import contactImg from '../images/contactImg.png';

function ContactInfo() {
  return (
    <>
    <div className='contactInfo'>
      <div id='contactImg'>
        <img src={contactImg} width='300px' height='100px' alt='Contact Me'/>        
      </div>
      <div id='feedbackContainer'>          
        <form id='feedbackForm' action="mailto:charlesgamble@execulink.com" method="post" enctype="text/plain" autocomplete="off">
          <input id='userName' type='text' placeholder='your name' autocomplete='false' aria-label='username'/>
          <input id='subject' type='text' placeholder='subject' autocomplete='false' aria-label='subject'/>
          <input id='emailAddress' type='email' placeholder='your email' autocomplete='false' aria-label='email'/>
          <textarea id='message' placeholder='type message here...' rows='20' cols='80' autocomplete='false' aria-label='message'></textarea>
          <input id='sendMsg' type='submit' value='Send Message' />
        </form>
      </div>
    </div>
    </>  
  )
} // Contact Info

export default ContactInfo;

