
import React from 'react';
import styled from 'styled-components/';
import AboutInfo from './AboutInfo';
import AboutImg from '../images/aboutBkg.jpg';

const AboutWrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  background-image: url(${AboutImg});
  background-position: center;
  background-size: cover;
  margin-top: 0px;
  object-fit: fit;
  overflow: hidden;
`; // AboutWrapper

const AboutInfoWrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  margin-top: 0px;
  overflow: hidden;
`; // AboutInfoWrapper

function About () {
  return (
    <>
    <AboutWrapper id='About'>
      <AboutInfoWrapper>
        <AboutInfo />
      </AboutInfoWrapper>
    </AboutWrapper> 
    </>
  )
} // About

export default About

