import React from 'react'
import FlipCard from './FlipCard'

function FlippingCard() {
  return (
    <div>
      <FlipCard />
    </div>
  )
} // FlippingCard

export default FlippingCard;
