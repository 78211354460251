import $ from 'jquery';
import './App.css';
import Navbar from './components/Navbar';
import TopIcon from './components/TopIcon';
import Home from './components/Home';
import Intro from './components/Intro';
import About from './components/About';
import Skills from './components/Skills';
import Projects from './components/Projects';
import Contact from './components/Contact';
import Lightbox from './components/Lightbox';

function App() {
  let isShowing = false;
  const toggleLightbox = (imgfile) => {
    if(!isShowing) {
      $('#lightbox').attr('class', 'show');
      $('#lightboxImage').attr('src', imgfile);
    }
    else {
      $('#lightbox').attr('class', 'hide');
      $('#lightboxImage').attr('src', ' ');
    }
    isShowing = !isShowing;
  }; 
  return (
    <div className="App">
      <Lightbox open={toggleLightbox} />
      <TopIcon />
      <Navbar />
      <Home />
      <Intro />
      <About />
      <Skills />
      <Projects open={toggleLightbox} />
      <Contact />
    </div>
  );
} // App

export default App;
