import React from 'react'
import styled from 'styled-components'
import topBtn from '../images/topBtn.png'
import topBtnHover from '../images/topBtnHover.png'


const BtnStyle = styled.div`
  height: 100px;
  width: 100px;
  bottom: 10px;
  position: fixed;
  right: 10px;
  z-index: 5;
`; // BtnStyle

const UpTop = styled.img`
  height: 100px;
  width: 100px;
  background-image: url(${topBtn});
  border-radius: 50%;

  &:hover {
    background-image: url(${topBtnHover});
  }
`; // UpTop

const TopIcon = () => {
  return (
    <>
      <BtnStyle>
          <a href='#Home' ><UpTop /></a>
      </BtnStyle>
    </>  
  )
} // TopIcon

export default TopIcon