import React from 'react';
import Videoback from '../videos/homeBkg.mp4';
import styled from 'styled-components';
import Name from '../images/Name.png';

const HomeWrapper = styled.div`
  height: 100vh;
  width: 100%; 
  display: flex;
  flex-shrink: 1;
`; // HomeWrapper

const BackVideo = styled.div`
  height: 100vh;
  width: 100%;
  position: fixed;
  z-Index: -1;
`; // BackVideo

const HomeScreen = styled.img`
  height: 100vh;
  width: 100%;
  display: flex;
  object-fit: fit;
  z-Index: 1;
`; // HomeScreen

const Look = styled.h1`
  color: #fff;
  font-size: 3.5rem;
  font-weight: bold;
  margin-top: -525px;
  text-align: center;

  @media screen and (max-width: 902px) {
    font-size: 3rem;
    margin-top: -540px;
  }
`; // Look

const Home = () => {
  return (
    <HomeWrapper id='Home'>
      <BackVideo>
        <video src={Videoback} autoPlay loop muted />
      </BackVideo>
      <div>
        <HomeScreen src={Name} />
        <Look>Charles Gamble | Come Look Inside</Look>
      </div>
    </HomeWrapper>
  )
}  // Home

export default Home
